<template>
    <NavHeader/>
    <main class="container">
      <div class="img-container">
        <img src="@/assets/luisdtorresp.jpg" alt="Luis Torres" title="Let's work!">
      </div>
        <div class="info-container">
          <p class="subtitle">
            Have a challenging project in mind?
          </p>
          <h2 class="title">Let's work Together!</h2>
        </div>
      <section class="form-container">
        <form action="">
          <label for="name">Name</label>
          <input type="text" name="name">
          <label for="email">Email</label>
          <input type="email" name="email">
          <label for="email">Message</label>
          <textarea name="msg" id="" cols="30" rows="10" placeholder="Message here..."></textarea>
          
          <button name="send" type="submit">
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" width="1.4rem" height="1.4rem" fill="currentColor" class="bi bi-send-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89.471-1.178-1.178.471L5.93 9.363l.338.215a.5.5 0 0 1 .154.154l.215.338 7.494-7.494Z"/>
              </svg>
            </i>
            <strong>Send</strong>
          </button>
        </form>
        <p> <br><br> or <strong>reach me</strong> at <a href="mailto:luisdtorresp@gmail.com">luisdtorresp@gmail.com</a></p>
      </section>
    </main>
  <footer class="grey">
    <NavFooter/>
  </footer>
</template>

<script>
import NavHeader from '@/components/Navbar.vue'
import NavFooter from '@/components/Footer.vue'
/*import Development from '@/components/Development.vue'*/

export default {
  name: 'Contact',
  components: {
    NavHeader,
    NavFooter
  }
}
</script>

<style>
  main.container {
    max-width: 1600px;
    height: fit-content;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin: auto;
  }

  section.form-container{
    width: 50%;
    padding: 2%;
    box-sizing: border-box;

  }

  .form-container form{
    display: flex;
    flex-flow: column nowrap;
    margin: auto;
  }
  .grey{
    background-color: #e3e3e3;
  }
   
  .img-container{
    width: 45%;
    height: 100%;
    min-width: 350px;
    max-width: 90%;
  }
  .img-container img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;

  }
  .info-container {
    padding: 2rem 0;
  }
  .info-container .title{
    font-size: 3rem;
    margin-top: 0;
  }

  .info-container .subtitle{
    margin: 0 auto;
    color: #999;
  }


  input, textarea {
      padding: 0.5rem;
      margin: 1%;
      border: 1px solid #ccc;
  }

  label{
    margin-top: 1rem;
  }

  #send-btn, .sender{
    height: 6rem;
    width: 30%;
  }

  button{
    background-color: transparent;
    border: solid 2px #333;
    width: 40%;
    margin: auto;
    box-sizing: border-box;
    padding: 0.75rem;
    margin-top: 1.2em;
    transition: all ease-in-out 0.3s;
  }

  button i {
    margin-right: 3%;
  }

  button:hover, button:focus{
    border-radius: 1rem ;
    box-shadow: 0 0 6px 0.25rem #009c49;
    transform: scale(1.1);
    
  }

  @media (max-width:1000px) {
    main.container {
      width: 100%;
      margin-top: 5rem;
      flex-flow: column;
    }
    .img-container{ 
      width: 100%;
      height: 55vh;
      min-width: 350px;
      max-width: 100%;
    }
    .img-container img{
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: top;
    }

    section.form-container{
      width: 100%;
    }
    .info-container{
      order:-1;
      padding: 3.5rem 2rem;
    }

    .info-container .title{
      margin: 0;
    }
  }
</style>
